import React, { useState } from "react";
import css from './NewsLetterSubscription.module.css'
import Button, { PrimaryButtonInline } from "../Button/Button";
import submitIcon from './submitIcon.svg';
import { postMailerLite } from "../../util/api";
import Spinner from "../IconSpinner/IconSpinner";

const NewsLetterSubscription = props => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const addToNewsLetter = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            setError(null)
            setSuccess(null)
            const response = await postMailerLite({ email })
            if (response.success) {
                setSuccess(`${email} added to mailing list`)
                setEmail("")
            } else {
                setError(response.message)
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
            setError("Server Error")
        }
    }


    return <div className={css.newsletterWrapper}>
        <span className={css.subtitle}>newsletter</span>
        <h2 className={css.title}>Subscribe to our newsletter</h2>
        <p className={css.description}>Stay in the loop with Labayk's latest updates! Sign up to our newsletter for exclusive news and updates on everything new coming your way.</p>
        <form className={css.formWrapper} onSubmit={addToNewsLetter}>
            <input className={css.emailInput} type="email" onChange={e => setEmail(e.target.value)} value={email} />
            <PrimaryButtonInline className={css.submitButton} type="submit">
                {loading ? <Spinner className={css.spinner} /> : <img src={submitIcon} />}
            </PrimaryButtonInline>
        </form>
        {error && <span className={css.error}>{error}</span>}
        {success && <span className={css.success}>{success}</span>}
    </div>
}

export default NewsLetterSubscription
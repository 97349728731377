import React from "react";
import HowItWorksCard from "./HowItWorksCard/HowItWorksCard";
import css from './HowItWorksSection.module.css';

// How it works Images
import postANewListingSrc from './images/postANewListing.png'
import findWhatYouNeedSrc from './images/findWhatYouNeed.png'
import leaveAReviewSrc from './images/leaveAReview.png'
import sellerDeliversFoodsSrc from './images/sellerDeliversFoods.png'
import makeASecurePaymentSrc from './images/makeASecurePayment.png'


const HowItWorksSection = () => {
    return <div className={css.root}>
        <div className={css.divider}><div></div></div>
        <div className={css.startCircle}></div>
        <div className={css.endCircle}></div>
        <div className={css.howItWorksCardWrapper}>
        <HowItWorksCard
            imgSrc={postANewListingSrc}
            label={"Post a new listing"}
            description="List your product or service. Accept payments from customers and receive money to your bank account"
            count={1}
            reverseInDesktop={false}
        />
        <HowItWorksCard
            imgSrc={findWhatYouNeedSrc}
            label={"Find what you need"}
            description="Search by location or keyword. Filter by price, availability, or custom filters. View photos and listing details."
            count={2}
            reverseInDesktop={true}
        />
        <HowItWorksCard
            imgSrc={makeASecurePaymentSrc}
            label={"Make a secure payment"}
            description="Pay with a credit card. Labayk holds your money until you've received what you bought."
            count={3}
            reverseInDesktop={false}
        />
        <HowItWorksCard
            imgSrc={sellerDeliversFoodsSrc}
            label={"Seller delivers goods"}
            description="Labayk releases payment to seller only after goods have been delivered."
            count={4}
            reverseInDesktop={true}
        />
        <HowItWorksCard
            imgSrc={leaveAReviewSrc}
            label={"Leave a review"}
            description="Both buyers and sellers can leave a review of the transaction."
            count={5}
            reverseInDesktop={false}
        />
        </div>
    </div>
}

export default HowItWorksSection
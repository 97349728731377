import React from "react";
import css from './HowItWorksCard.module.css'
import classNames from "classnames";

const HowItWorksCard = props => {
    const { imgSrc, label, description, count, reverseInDesktop = false } = props

    return <div className={classNames(css.card, reverseInDesktop && css.reverseInDesktop)}>
        <div className={classNames(css.cardImageWrapper)}>
            <img src={imgSrc} className={css.cardImage} />
        </div>
        <div className={classNames(css.contentWrapper)}>
            <div className={css.countLabel}>{count}</div>
            <span className={css.cardLabel}>{label}</span>
            <p>{description}</p>
        </div>
    </div>
}

export default HowItWorksCard
import React, { useState } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { H3, Page, UserNav, LayoutSingleColumn, Button, NamedLink } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import css from './CustomLandingPage.module.css'
import { isScrollingDisabled } from '../../ducks/ui.duck';
import labaykLandingPageVideo from '../../assets/Labayk 15s.mp4'
import CategoriesCard from './CategoriesCard/CategoriesCard';
import classNames from 'classnames';

import charityLogos from './charityLogos.png'
import charityLogosMobile from './charityLogosMobile.png'
import strugglingVeteran from './strugglingVeteran.png'

//Category Images
import bookSrc from './categoryImages/book.png'
import furnitureSrc from './categoryImages/furniture.png'
import jewellrySrc from './categoryImages/jewellry.png'
import fashionSrc from './categoryImages/fashion.png'
import motherAndBabySrc from './categoryImages/motherAndBaby.png'
import homeAndGardenSrc from './categoryImages/homeAndGarden.png'
import electronicsSrc from './categoryImages/electronics.png'
import healthAndBeautySrc from './categoryImages/healthAndBeauty.png'
import artSrc from './categoryImages/art.png'
import othersSrc from './categoryImages/others.png'
import HowItWorksSection from './HowItWorksSection/HowItWorksSection';
import ProviderSection from './ProviderSection/ProviderSection';
import NewsLetterSubscription from '../../components/NewsLetterSubscription/NewsLetterSubscription';
import { YoutubeEmbed } from '../PageBuilder/Primitives/YoutubeEmbed';
import CharityLogos from './CharityLogos/CharityLogos';

function ManageFavouritesPageComponent(props) {
  const {
    intl,
    scrollingDisabled,
  } = props;


  const title = intl.formatMessage({ id: 'CustomLandingPage.schemaTitle' });


  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={<>
          <TopbarContainer />
        </>}
        footer={<FooterContainer hasNewsletter={true} />}
        topBarBackgroundColor="transparent"
      >
        <div className={css.root}>
          <div className={css.heroSection}>
            <div className={css.twoPanelView}>
              <div className={css.heroContent}>
                <h1>Shopping for Good: Every Purchase Powers Change!</h1>
                <div className={css.heroDescriptionWrapper}>
                  <p>
                    Labayk is a new type of online marketplace where all profits will be donated to charities
                    nominated by buyers and sellers.
                  </p>
                  <p>
                    A marketplace that benefits the most vulnerable in
                    society without making buyers or sellers any worse
                    off.
                  </p>
                  <p>
                    It is absolutely FREE to list items for sale on Labayk.
                  </p>
                </div>
                <NamedLink style={{ textDecoration: 'none' }} name='SearchPage'>
                  <Button className={css.heroActionButton}>BROWSE LISTINGS</Button>
                </NamedLink>
              </div>
              <div className={css.heroVideoWrapper}>
                <div dangerouslySetInnerHTML={{
                  __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${labaykLandingPageVideo}"
          class="${css.heroVideo}"
        />,
      ` }}></div>

              </div>
            </div>
          </div>

          <div className={css.section} >
            <div className={css.contentWrapper}>
              <h2>Charities We Support*</h2>
              <p>100% of our profits are donated to these charities.</p>
              <CharityLogos />
            </div>
          </div>

          <div className={css.section} style={{ backgroundColor: "#8DD7BB", color: "#0B1B36" }}>
            <div className={css.contentWrapper}>
              <h2>Buy Anything From Anyone</h2>
              <p>Labayk has something for every member of the family.</p>
              <div className={classNames(css.categoryWrapper)}>
                <CategoriesCard
                  imgSrc={bookSrc}
                  label={"Books"}
                  url={"/s?pub_category=books"}
                />
                <CategoriesCard
                  imgSrc={furnitureSrc}
                  label={"Furniture"}
                  url={"/s?pub_category=furniture"}
                />
                <CategoriesCard
                  imgSrc={jewellrySrc}
                  label={"Jewellery"}
                  url={"/s?pub_category=jewellery"}
                />
                <CategoriesCard
                  imgSrc={fashionSrc}
                  label={"Fashion"}
                  url={"/s?pub_category=fashion"}
                />
                <CategoriesCard
                  imgSrc={motherAndBabySrc}
                  label={"Mother & Baby"}
                  url={"/s?pub_category=mother-and-baby"}
                />
              </div>
              <div className={css.categoryWrapper}>
                <CategoriesCard
                  imgSrc={homeAndGardenSrc}
                  label={"Home & Garden"}
                  url={"/s?pub_category=home-and-garden"}
                />
                <CategoriesCard
                  imgSrc={electronicsSrc}
                  label={"Electronics"}
                  url={"/s?pub_category=electronics"}
                />
                <CategoriesCard
                  imgSrc={healthAndBeautySrc}
                  label={"Health Beauty"}
                  url={"/s?pub_category=health-and-beauty"}
                />
                <CategoriesCard
                  imgSrc={artSrc}
                  label={"Art"}
                  url={"/s?pub_category=art"}
                />
                <CategoriesCard
                  imgSrc={othersSrc}
                  label={"Other"}
                  url={"/s?pub_category=other"}
                />
              </div>
            </div>
          </div>

          <div className={css.section} >
            <div className={css.contentWrapper}>
              <h2>How It Works</h2>
              <p>Buying and selling products on Labayk is very simple and easy.</p>
              <HowItWorksSection />
            </div>
          </div>

          <div className={css.section} style={{ backgroundColor: "#8DD7BB", color: "#0B1B36" }}>
            <div className={css.contentWrapper}>
              <h2>
                Have something to sell?<br></br>
                List it and start making money!
              </h2>
              <p>On Labayk you can make cash by selling your items. It does not matter if you are an established provider or a first timer, everybody can do it!</p>
              <ProviderSection />
            </div>
          </div>

          <div className={css.section}>
            <div className={css.contentWrapper}>
              <h2>Labayk – A Force For Good</h2>
              <p>Discover our commitment to democratise and transform online shopping, channeling ALL profits back to society rather than shareholders.</p>
              <div className={css.videoBackground}>
                <YoutubeEmbed youtubeVideoId='WA1hbZWB9QE' />
              </div>
            </div>
          </div>

          <div className={css.section} style={{ backgroundColor: "#EAFFF8", color: "#0B1B36" }}>
            <div className={css.socialEnterpriseTwoPanelView}>
              <div className={css.socialEnterpriseImageWrapper}>
                <img src={strugglingVeteran} className={css.videoBackground} />
              </div>
              <div className={css.socialEnterprise}>
                <h2>Labayk is a social enterprise</h2>
                <div className={css.heroDescriptionWrapper}>
                  <p>
                    Did you know if you buy something on Labayk, you make a real difference to someone’s life?
                  </p>
                  <span>
                    This means ALL profits we make are donated to charities nominated by buyers and sellers.
                  </span>
                  <p>
                    We call this ‘A Force For Good’.
                  </p>
                </div>
                <a style={{ textDecoration: 'none' }} href='/p/social-impact'>
                  <Button className={css.actionButton}>READ OUR STORY</Button>
                </a>
              </div>
            </div>
            <div className={css.newsLetterSection}><NewsLetterSubscription /></div>
          </div>

        </div>
      </LayoutSingleColumn>
    </Page>
  )
};

const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({

});

const CustomLandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ManageFavouritesPageComponent);

export default CustomLandingPage;

import React from "react";
import css from './CategoriesCard.module.css'

const CategoriesCard = props => {
    const { imgSrc, label, url } = props

    return <a href={url} style={{ textDecoration: 'none' }} className={css.card}>
        <div className={css.cardImageWrapper}>
            <img src={imgSrc} className={css.cardImage} />
        </div>
        <span className={css.cardLabel}>{label}</span>
    </a>
}

export default CategoriesCard
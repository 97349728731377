import React from "react";
import css from './ProviderSection.module.css'
import ProviderCard from "./ProviderCard/ProviderCard";

import listYourItemSrc from './listYourItem.png'
import addYourBankDetailsSrc from './addYourBankDetails.png'
import startSellingSrc from './startSelling.png'
import scheduleDeliverySrc from './scheduleDelivery.png'

const ProviderSection = props => {
    return <div className={css.root}>
        <ProviderCard
            imgSrc={listYourItemSrc}
            label="List your items"
            description="Decide a price and add a few photos of your item"
        />
         <ProviderCard
            imgSrc={addYourBankDetailsSrc}
            label="Add your bank details"
            description="Add your bank account number and go through an identity verification process so we can send you money for your sales"
        />
         <ProviderCard
            imgSrc={startSellingSrc}
            label="Start selling"
            description="You’ll get notified by email when there’s a new sale"
        />
         <ProviderCard
            imgSrc={scheduleDeliverySrc}
            label="Schedule delivery"
            description="Arrange delivery of the items sold."
        />
    </div>
}

export default ProviderSection
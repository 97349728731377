import React from "react";

import css from './CharityLogos.module.css'
import CharityLogoCard from "./CharityLogoCard/CharityLogoCard";

import medicalAidForPalestinians from './map.png';
import waterAid from './wateraid-logo.png';
import shelter from './shelter.png';
import worldCentralKitcher from './world-central.png';
import waterCan from './water-can.png';
import unrwa from './unrwa.png';
import worldFoodProgramme from './wfp.png';
import greatOrmondStreetHospital from './great-ormond.png';
import islamicRelief from './islamic-relief.png';
import saveTheChildren from './save-the-children.png';
import theSalvationArmy from './the-salvon-army.png';
import unicef from './unicef-logo.png';

const CharityLogos = props => {
    return <div className={css.charityLogoWrapper}>
        <CharityLogoCard
            imgSrc={medicalAidForPalestinians}
        />
        <CharityLogoCard
            imgSrc={waterAid}
        />
        <CharityLogoCard
            imgSrc={shelter}
        />
        <CharityLogoCard
            imgSrc={worldCentralKitcher}
        />
        <CharityLogoCard
            imgSrc={waterCan}
        />
        <CharityLogoCard
            imgSrc={unrwa}
        />
        <CharityLogoCard
            imgSrc={worldFoodProgramme}
        />
        <CharityLogoCard
            imgSrc={greatOrmondStreetHospital}
        />
        <CharityLogoCard
            imgSrc={islamicRelief}
        />
        <CharityLogoCard
            imgSrc={saveTheChildren}
        />
        <CharityLogoCard
            imgSrc={theSalvationArmy}
        />
        <CharityLogoCard
            imgSrc={unicef}
        />
    </div>
}

export default CharityLogos
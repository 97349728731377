import React from "react";
import css from './ProviderCard.module.css'
import classNames from "classnames";

const ProviderCard = props => {
    const { imgSrc, label, description } = props

    return <div className={classNames(css.card)}>
        <div className={classNames(css.cardImageWrapper)}>
            <img src={imgSrc} className={css.cardImage} />
        </div>
        <div className={classNames(css.contentWrapper)}>
            <span className={css.cardLabel}>{label}</span>
            <span className={css.description}>{description}</span>
        </div>
    </div>
}

export default ProviderCard
import React from "react";

import css from './CharityLogoCard.module.css'

const CharityLogoCard = props => {
    const { imgSrc } = props

    return <div className={css.card}>
        <img src={imgSrc} />
    </div>
}

export default CharityLogoCard